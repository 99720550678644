import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';


import App from './App';

window.jQuery = $;
require('bootstrap');

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

ReactDOM.render(app, document.getElementById('root'));
